<template>
  <div class="page-wrapper">
    <header class="main-header always-sticky">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant" />
        <img v-if="$route.meta.lang === 'fr'" src="@/assets/img/tagline-fr.svg" class="tagline" alt="Tagline Tremblant" />
        <img v-else-if="$route.meta.lang === 'en'" src="@/assets/img/tagline-en.svg" class="tagline" alt="Tagline Tremblant" />
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18663562247">1-866-356-2247</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang"
            >FR</router-link
          >
        </div>
        <a :href="$t('site.privilege-cta-link')" target="_blank" class="cta red">{{ $t('site.achetez-forfait') }}</a>
      </div>
      <a href="tel:18663562247" class="phone-mobile"><img src="@/assets/img/phone.svg" alt="Call Tremblant" /></a>
      <div class="mobile-cta">
        <a :href="$t('site.privilege-cta-link')" target="_blank" class="cta red">{{ $t('site.achetez-forfait') }}</a>
      </div>
    </header>

    <section class="terms-wrap">
      <div v-if="$route.meta.lang === 'fr'">
        <button class="cta prune" @click.prevent="goback">Retour</button><br /><br />

        <h1 class="big-title">Modalités<br />FORFAIT DE PRIVILÈGES</h1>

        <h2 class="small-title">Validité</h2>
        <ul>
          <li class="regular-text">Les produits inclus dans le Forfait de privilèges exclusif aux détenteurs ont des périodes de validité spécifiques :</li>
          <ul>
            <li class="regular-text">Premières traces : 17 décembre 2022 au 19 mars 2023, lors des journées de validité de la passe du détenteur.</li>
            <li class="regular-text">20 cafés ou chocolats chauds : Valables du 24 novembre 2022 au 16 avril 2023.</li>
            <li class="regular-text">15 % de rabais dans les cafétérias Tremblant : Valable du 24 novembre 2022 au 16 avril 2023.</li>
            <li class="regular-text">15 % de rabais dans les boutiques Tremblant Valable du 1er septembre 2021 au 16 avril 2023.</li>
            <li class="regular-text">200 $ dans les boutiques Tremblant : Valable du 1er septembre 2022 au 31 janvier 2023.</li>
            <li class="regular-text">1 mise au point pour le lendemain : Valable de l’ouverture du Ski Shop au 16 avril 2023. Pour une mise au point la journée-même, des frais additionnels de 5 $ plus taxes seront appliqués.</li>
          </ul>
        </ul>

        <h2 class="small-title">Achat</h2>
        <ul>
          <li class="regular-text">Le Forfait de privilèges pour détenteurs peut être acheté en même temps qu’une passe de saison, ou séparément, en personne au Service à la clientèle du Chalet des voyageurs, en ligne ou par téléphone.</li>
          <li class="regular-text">Pour être activé, le Forfait de privilèges doit obligatoirement être associé avec votre passe.</li>
          <li class="regular-text">L’âge de référence est celui à la date de l’achat.</li>
          <li class="regular-text">Rabais de 15 % pour le Club des Montagnards</li>
          <li class="regular-text">Achat par téléphone au 1-866-802-8867 ou en personne au comptoir du Service à la clientèle.</li>
        </ul>

        <h2 class="small-title">Livraison</h2>
        <ul>
          <li class="regular-text">Le Forfait de privilèges exclusif aux détenteurs sera ajouté à votre passe de saison Ikon Pass, Ikon Base Pass ou Tonik. Si vous achetez ce produit en ligne, vous devez ouvrir la session avec votre profil existant et l’associer à votre passe. Tout nouveau détenteur de passe devra se présenter au Service à la clientèle pour récupérer sa passe (pièce d’identité avec photo obligatoire).</li>
        </ul>

        <h2 class="small-title">Remboursement</h2>
        <ul>
          <li class="regular-text">Non remboursable, non assurable.</li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">Le Forfait de privilèges exclusif aux détenteurs est incessible (ne peut être transférée d'une personne à une autre).</li>
          <li class="regular-text">1 forfait maximum par personne, par saison.</li>
        </ul>
      </div>

      <div v-else-if="$route.meta.lang === 'en'">
        <button class="cta prune" @click.prevent="goback">Back</button><br /><br />

        <h1 class="big-title">Tremblant Ticket Outlet<br />PRIVILEGE BUNDLE</h1>

        <h2 class="small-title">Validity</h2>
        <ul>
          <li class="regular-text">The products included in the Privilege Bundle for passholders have specific validity periods:</li>
          <ul>
            <li class="regular-text">First Tracks: valid from December 17, 2022, to March 19, 2023, on days when the pass is valid. </li>
            <li class="regular-text">20 coffees or hot chocolates: Valid from November 24, 2022, to April 16, 2023.</li>
            <li class="regular-text">15% off in Tremblant cafeterias: Valid from November 24, 2022, to April 16, 2023.</li>
            <li class="regular-text">15% off in Tremblant Boutiques: Valid from September 1, 2022, to April 16, 2023.</li>
            <li class="regular-text">$200 in Tremblant Boutiques: Valid from September 1, 2022, to January 31, 2023.</li>
            <li class="regular-text">1 free tune-up at Le Ski Shop: Valid from the opening of the Ski Shop to April 16, 2023. To get your skis or snowboard tuned up for the same day, an additional $5 fee plus royalty and taxes will apply.</li>
          </ul>
        </ul>

        <h2 class="small-title">Purchase and Booking</h2>
        <ul>
          <li class="regular-text">The Privilege Bundle exclusive to passholders can be purchased—with your season pass or separately—online, by phone or at the Chalet des Voyageurs Customer Services Desk.</li>
          <li class="regular-text">Age of reference is age on date of purchase.</li>
          <li class="regular-text">To be activated, the Privilege Bundle must be linked to your pass.</li>
          <li class="regular-text">Club des Montagnards 15% discount</li>
          <li class="regular-text">Purchase by phone at 1-866-802-8867 or in person at our Customer Services Desk.</li>
        </ul>

        <h2 class="small-title">Delivery</h2>
        <ul>
          <li class="regular-text">The Privilege Bundle exclusive to passholders will be added to your Ikon Pass, Ikon Base Pass or Tonik Pass. If you purchase this product online, you must open your session with your existing profile and associate the right bundle to your pass. New passholders will have to pick up their pass at Customer Services (with photo ID).</li>
        </ul>

        <h2 class="small-title">Refund</h2>
        <ul>
          <li class="regular-text">Non-refundable. Cannot be insured.</li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">The Privilege Bundle cannot be transferred.</li>
          <li class="regular-text">Maximum of 1 bundle per person, per season.</li>
        </ul>

      </div>
    </section>

    <footer-bar></footer-bar>

    <!-- <div class="logo-partenaire">
      <a v-if="$route.meta.lang === 'en'" href="https://www.laurentides.com/en" target="_blank"
        ><img src="@/assets/img/logo-tl-en.png" alt="Logo Tourisme Laurentides"
      /></a>
    </div> -->

    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform" />
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              v-model="time1"
              name="arrivaldate"
              :lang="this.datepickerLang"
              :disabled-date="disabledBeforeToday"
              :input-attr="{ name: 'arrivaldate', required: 'required' }"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              v-model="time2"
              name="departuredate"
              :lang="this.datepickerLang"
              :disabled-date="disabledBeforeToday"
              :input-attr="{ name: 'departuredate', required: 'required' }"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input name="adultcount" type="number" value="2" min="1" required />
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input name="childCount" type="number" value="0" required />
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')" />
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: this.$t(`site.privileges-meta-title`),
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: this.$t(`site.privileges-meta-title`) },
        {
          name: 'description',
          content: this.$t(`site.privileges-meta-desc`),
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: this.$t(`site.privileges-meta-title`) },
        { name: 'twitter:card', content: 'Tremblant' },
        {
          n: 'twitter:description',
          c: this.$t(`site.privileges-meta-desc`),
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t(`site.privileges-meta-title`) },
        { property: 'og:site_name', content: 'Tremblant' },
        {
          property: 'og:description',
          content: this.$t(`site.privileges-meta-desc`),
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://promo.tremblant.ca/vue-app-static-img/img/bighit/avt-dink/hero.jpg',
        },
        {
          name: 'twitter:image',
          content: 'https://promo.tremblant.ca/vue-app-static-img/img/bighit/avt-dink/hero.jpg',
        },
      ],
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null,
    }
  },
  computed: mapState('app', ['appTitle']),
  mounted() {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
    window.addEventListener('scroll', () => {
      this.snowscroll()
    })
  },
  methods: {
    goback() {
      return this.$router.go(-1)
    },
    table1() {
      this.toggle = true
    },
    table2() {
      this.toggle = false
    },
    showlodgingform() {
      if (this.formlodging) {
        this.formlodging = false
      } else {
        this.formlodging = true
      }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
      if (this.sidepanel) {
        this.sidepanel = false
      } else {
        this.sidepanel = true
      }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${-150 + st / 2}px`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
